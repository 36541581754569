<template>
  <Modal v-show="!isLoading" @closemodal="$emit('closemodal')" :with-footer="true" :dismissible="true">
    <template v-slot:default>
      <Card :title="title" :with-footer="false" subtitle="cycles.modal.subtitle">
        <template v-slot:default>
          <div class="col-lg-10">
            <div class="form-group">
              <TextInput :reference="cycle" @input="setName" :value="cycle.getName()" :placeholder="$t('cycles.modal.placeholder.name')" v-bind:class="v$.cycle.name.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.cycle.name.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.process_order') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.orderBatch.dayOfWeek" :model-value="cycle.orderBatch.dayOfWeek" :options="daysOptions" :settings="orderBatchDaySelect2settings" v-bind:class="v$.cycle.orderBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.orderBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.process_order_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.orderBatch.time" :options="timeOptions" :settings="orderBatchTimeSelect2settings" v-bind:class="v$.cycle.orderBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.orderBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.send_to_farm_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.sendToFarmBatch.dayOfWeek" :options="daysOptions" :settings="sendToFarmBatchDaySelect2settings" v-bind:class="v$.cycle.sendToFarmBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.sendToFarmBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.send_to_farm_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.sendToFarmBatch.time" :options="timeOptions" :settings="sendToFarmBatchTimeSelect2settings" v-bind:class="v$.cycle.sendToFarmBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.sendToFarmBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.prepack_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.prepackBatch.dayOfWeek" :options="daysOptions" :settings="prepackBatchDaySelect2settings" v-bind:class="v$.cycle.prepackBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.prepackBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.prepack_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.prepackBatch.time" :options="timeOptions" :settings="prepackBatchTimeSelect2settings" v-bind:class="v$.cycle.prepackBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.prepackBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.pack_batch') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.packBatch.dayOfWeek" :options="daysOptions" :settings="packBatchDaySelect2settings" v-bind:class="v$.cycle.packBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.packBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.pack_batch_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.packBatch.time" :options="timeOptions" :settings="packBatchTimeSelect2settings" v-bind:class="v$.cycle.packBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.packBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.send_to_collection') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.sendToCollectionBatch.dayOfWeek" :options="daysOptions" :settings="sendToCollectionDaySelect2settings" v-bind:class="v$.cycle.sendToCollectionBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.sendToCollectionBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.send_to_collection_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.sendToCollectionBatch.time" :options="timeOptions" :settings="sendToCollectionTimeSelect2settings" v-bind:class="v$.cycle.sendToCollectionBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.sendToCollectionBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.collection') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.collectBatch.dayOfWeek" :options="daysOptions" :settings="collectBatchDaySelect2settings" v-bind:class="v$.cycle.collectBatch.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.collectBatch.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.collection_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.collectBatch.time" :options="timeOptions" :settings="collectBatchTimeSelect2settings" v-bind:class="v$.cycle.collectBatch.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.collectBatch.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('cycles.modal.delivery_order') }}</label>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <Select2 v-model="cycle.deliverOrder.dayOfWeek" :options="daysOptions" :settings="deliveryOrderDaySelect2settings" v-bind:class="v$.cycle.deliverOrder.dayOfWeek.$error && 'error'"/>
                  <div class="input-errors" v-for="(error, index) of v$.cycle.deliverOrder.dayOfWeek.$errors" :key="index">
                    <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center">
                      <span>{{ $t('cycles.modal.delivery_order_time') }}</span>
                    </div>
                    <div class="col-9">
                      <Select2 v-model="cycle.deliverOrder.time" :options="timeOptions" :settings="deliveryOrderTimeSelect2settings" v-bind:class="v$.cycle.deliverOrder.time.$error && 'error'"/>
                      <div class="input-errors" v-for="(error, index) of v$.cycle.deliverOrder.time.$errors" :key="index">
                        <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
      <NotificationAlert :notifications="notification" />
    </template>
    <template v-slot:footer>
      <Button v-bind:disabled="canDisableButtons" @click="createCycle" class="mr-auto mb-5 mb-lg-auto" v-if="!cycle.isLoaded()" :name="$t('cycles.buttons.create_small')" type="confirm"></Button>
      <Button v-bind:disabled="canDisableButtons" @click="updateCycle" class="mr-auto mb-5 mb-lg-auto" v-else :name="$t('cycles.buttons.update')" type="confirm"></Button>
    </template>
  </Modal>
</template>

<script>
import Card from "@/components/elements/Card";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizationMixin, ValidationsMixin, NotificationsMixin, DatesMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import NotificationAlert from "@/components/elements/NotificationAlert";
import Select2 from 'vue3-select2-component';
import TextInput from "@/components/elements/TextInput";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "CycleModal",
  components: {NotificationAlert, Button, Modal, Card, Select2, TextInput},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin, DatesMixin],
  emits: ['closemodal'],
  watch: {
    cycle: {
      immediate: true,
      deep: true,
      handler: function () {
        this.cycle.setChanged(true);
      }
    },
  },
  validations: {
    cycle: {
      name: {
        required,
      },
      orderBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      sendToFarmBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      prepackBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      packBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      sendToCollectionBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      collectBatch: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
      deliverOrder: {
        dayOfWeek: {
          required,
        },
        time: {
          required,
        }
      },
    },
  },
  props: {
    selectedCycle: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    let that = this;
    return {
      cycle: this.selectedCycle,
      orderBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.process_order'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      orderBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.process_order_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToFarmBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_farm_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToFarmBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_farm_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      prepackBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.prepack_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      prepackBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.prepack_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      packBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.pack_batch'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      packBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.pack_batch_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToCollectionDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_collection'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      sendToCollectionTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.send_to_collection_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      collectBatchDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.collection'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      collectBatchTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.collection_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      deliveryOrderDaySelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.delivery_order'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      deliveryOrderTimeSelect2settings: {
        placeholder: this.$i18n.t('cycles.modal.placeholder.delivery_order_time'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
    }
  },
  beforeMount() {
  },
  computed: {
    title: function () {
      if (this.selectedCycle === null || !this.selectedCycle.isLoaded()) {
        return this.$t("cycles.modal.create_title");
      }

      return this.$t("cycles.modal.edit_title");
    },
    isFormReady: function () {
      return this.cycle.isChanged() && !(
          this.v$.cycle.name.$error ||
          this.v$.cycle.orderBatch.dayOfWeek.$error ||
          this.v$.cycle.orderBatch.time.$error ||
          this.v$.cycle.sendToFarmBatch.dayOfWeek.$error ||
          this.v$.cycle.sendToFarmBatch.time.$error ||
          this.v$.cycle.prepackBatch.dayOfWeek.$error ||
          this.v$.cycle.prepackBatch.time.$error ||
          this.v$.cycle.packBatch.dayOfWeek.$error ||
          this.v$.cycle.packBatch.time.$error ||
          this.v$.cycle.sendToCollectionBatch.dayOfWeek.$error ||
          this.v$.cycle.sendToCollectionBatch.time.$error ||
          this.v$.cycle.collectBatch.dayOfWeek.$error ||
          this.v$.cycle.collectBatch.time.$error ||
          this.v$.cycle.deliverOrder.dayOfWeek.$error ||
          this.v$.cycle.deliverOrder.time.$error
      );
    },
    canDisableButtons: function () {
      return !this.cycle.isChanged() || !this.isFormReady;
    }
  },
  methods: {
    setName: function (cycle, value)
    {
      cycle.setName(value);
      this.v$.cycle.name.$touch();
    },
    onLoadingComplete: function ()
    {
      this.$nextTick(() => document.getElementsByTagName("body")[0].classList.add('modal-open'));

      if (this.initialise && !this.canShowNotification) {
        this.$emit('closemodal');
      } else {
        this.addSuccessNotification("general.success");
      }
    },
    validateInputs: function ()
    {
      this.v$.cycle.name.$touch();
      this.v$.cycle.orderBatch.dayOfWeek.$touch();
      this.v$.cycle.orderBatch.time.$touch();
      this.v$.cycle.sendToFarmBatch.dayOfWeek.$touch();
      this.v$.cycle.sendToFarmBatch.time.$touch();
      this.v$.cycle.prepackBatch.dayOfWeek.$touch();
      this.v$.cycle.prepackBatch.time.$touch();
      this.v$.cycle.packBatch.dayOfWeek.$touch();
      this.v$.cycle.packBatch.time.$touch();
      this.v$.cycle.sendToCollectionBatch.dayOfWeek.$touch();
      this.v$.cycle.sendToCollectionBatch.time.$touch();
      this.v$.cycle.collectBatch.dayOfWeek.$touch();
      this.v$.cycle.collectBatch.time.$touch();
      this.v$.cycle.deliverOrder.dayOfWeek.$touch();
      this.v$.cycle.deliverOrder.time.$touch();
    },
    updateCycle: function ()
    {
      let that = this;
      let data = {
        'name': this.cycle.name,
        'batch_order': {
          'day': this.cycle.getOrderBatch().getDayOfWeek(),
          'time': this.cycle.getOrderBatch().getTime(),
        },
        'send_to_farm_batch': {
          'day': this.cycle.getSendToFarmBatch().getDayOfWeek(),
          'time': this.cycle.getPrepackBatch().getTime(),
        },
        'prepack_batch': {
          'day': this.cycle.getPrepackBatch().getDayOfWeek(),
          'time': this.cycle.getPrepackBatch().getTime(),
        },
        'pack_batch': {
          'day': this.cycle.getPackBatch().getDayOfWeek(),
          'time': this.cycle.getPackBatch().getTime(),
        },
        'send_to_collection_batch': {
          'day': this.cycle.getSendToCollectionBatch().getDayOfWeek(),
          'time': this.cycle.getSendToCollectionBatch().getTime(),
        },
        'collect_batch': {
          'day': this.cycle.getCollectBatch().getDayOfWeek(),
          'time': this.cycle.getCollectBatch().getTime(),
        },
        'deliver_order': {
          'day': this.cycle.getDeliverOrder().getDayOfWeek(),
          'time': this.cycle.getDeliverOrder().getTime(),
        },
      };

      this.validateInputs();
      if (!this.isFormReady) {
        return;
      }

      this.queueJob();
      this.resetNotifications();
      this.axios.put(this.$store.state.config.getCycleUri(this.cycle.getId()), data).then(
          () => {
            that.cycle.setLoaded(true);
          }
      ).catch(
          error => {
            console.warn(error);
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    createCycle: function ()
    {
      let that = this;
      let data = {
        'name': this.cycle.name,
        'batch_order': {
          'day': this.cycle.getOrderBatch().getDayOfWeek(),
          'time': this.cycle.getOrderBatch().getTime(),
        },
        'send_to_farm_batch': {
          'day': this.cycle.getSendToFarmBatch().getDayOfWeek(),
          'time': this.cycle.getSendToFarmBatch().getTime(),
        },
        'prepack_batch': {
          'day': this.cycle.getPrepackBatch().getDayOfWeek(),
          'time': this.cycle.getPrepackBatch().getTime(),
        },
        'pack_batch': {
          'day': this.cycle.getPackBatch().getDayOfWeek(),
          'time': this.cycle.getPackBatch().getTime(),
        },
        'send_to_collection_batch': {
          'day': this.cycle.getSendToCollectionBatch().getDayOfWeek(),
          'time': this.cycle.getSendToCollectionBatch().getTime(),
        },
        'collect_batch': {
          'day': this.cycle.getCollectBatch().getDayOfWeek(),
          'time': this.cycle.getCollectBatch().getTime(),
        },
        'deliver_order': {
          'day': this.cycle.getDeliverOrder().getDayOfWeek(),
          'time': this.cycle.getDeliverOrder().getTime(),
        },
      };

      this.validateInputs();
      if (!this.isFormReady) {
        return;
      }

      this.queueJob();
      this.resetNotifications();
      this.axios.post(this.$store.state.config.getCyclesUri(), data).then(
          response => {
            let content = response.data;
            that.cycle.setId(content.data.id);
            that.cycle.setLoaded(true);
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
  },
}
</script>

<style scoped>

</style>