<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('details', element)" :class="element.getId() % 2 == 0 ? 'even': 'odd'" v-for="element in elements.getItems()"
                    v-bind:key="element.getId()">
        <TableElementItem>
          <template v-slot:default>
            {{ element.getName() }}
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            {{ formatDay(element.getOrderBatch().getDayOfWeek()) }}
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            {{ formatDay(element.getDeliverOrder().getDayOfWeek()) }}
          </template>
        </TableElementItem>
        <TableElementItem class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button v-if="element.canDelete()" @click.stop="confirmDelete(element)" class="border-0 bg-white" name="cycles.buttons.delete">
              <span class="text-danger">X</span>
            </Button>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('cycles.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin, DatesMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Button from "@/components/elements/Button";
import EmittedEvent from "@/entities/EmittedEvent";

export default {
  name: "CycleTable",
  mixins: [ScreenSizeMixin, NumbersMixin, DatesMixin],
  emits: ['confirm', 'details'],
  components: {TableElement, TableElementItem, Table, Button},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
        'cycles.list.name',
        'cycles.list.process_order_day',
        'cycles.list.delivery_order_day',
        '',
      ],
      responsiveHeaders: [
        'cycles.list.name',
        'cycles.list.process_order_day',
        'cycles.list.delivery_order_day',
        '',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    }
  },
  methods: {
    confirmDelete: function (element) {
      this.$emit('confirm', new EmittedEvent('cycles.list.confirm.delete', element))
    }
  }
}
</script>

<style scoped>

</style>