<template>
  <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <CycleModal @closemodal="closeCycleModal" v-if="selectedCycle" :selectedCycle="selectedCycle"></CycleModal>
  <Card subtitle="cycles.list.info" :title="$t('cycles.list.title')">
    <template v-slot:default>
      <CycleTable v-if="hasFarms" @page="getCycles" @details="createOrUpdateCycle" @confirm="askConfirmation" :elements="cycles" />
      <div v-else class="text-center">
        <h3>{{$t('cycles.list.info_no_farms')}}</h3>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-if="hasFarms" @click="createOrUpdateCycle()" type="confirm" name="cycles.buttons.create" class="mb-0" />
      <Button v-if="canCleanupOwnApplication && !activeCycle && !isLoading" class="mt-2 mt-sm-0" @click="askConfirmation('general.cleanup_own')" type="delete" name="general.buttons.cleanup_own"/>
    </template>
  </Card>
</template>

<script>
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import {ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import LoadableCollection from "@/entities/LoadableCollection";
import CycleTable from "@/components/cycle/CycleTable";
import CycleModal from "@/components/cycle/CycleModal";
import Cycle from "@/entities/Cycle";
import Pagination from "@/entities/Pagination";
import {AuthorizedActionsMixin} from "@/mixins/GeneralMixin";

export default {
  name: "CycleList",
  components: {CycleTable, Card, ConfirmationModal, Button, CycleModal},
  mixins: [ConfirmationModalMixin, LoadingModalMixin, AuthorizedActionsMixin],
  data: () => {
    return {
      cycles: new LoadableCollection(),
      selectedCycle: null,

      hasFarms: false,
    }
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    }
  },
  beforeMount: function () {
    this.getFarmers(); //calls getCycles
  },
  methods: {
    onLoadingComplete: function ()
    {
      if (!this.initialise) {
        return;
      }
      this.getCycles();
    },
    createOrUpdateCycle: function (selectedCycle = null) {
      if (null === selectedCycle) {
        this.selectedCycle = new Cycle();
        return;
      }

      this.selectedCycle = selectedCycle;
    },
    closeCycleModal: function () {
      this.selectedCycle = null;
      this.getCycles();
    },
    getCycles(pageNr = 1) {
      this.cycles = new LoadableCollection();
      this.hydrateCycles(this.cycles, {}, pageNr);
    },
    confirmed: function () {
      switch (this.confirmationType) {
        case 'cycles.list.confirm.delete':
          this.deleteCycle(this.confirmedEvent.getPayload());
          break;
        case "general.cleanup_own":
          this.cleanupApplication();
          break;
        default:
          console.warn('Unknown confirmation event type', this.confirmationType);
      }

      this.closeConfirmation();
    },
    getFarmers(pageNr = 1) {
      let that = this;
      let filterParams = {
        page: pageNr,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getFarmersUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            that.hasFarms = content.data.total > 0;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
        if (that.hasFarms) {
          that.getCycles();
        }
      });
    },
    cleanupApplication: function () {
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getCleanupOwnApplicationUri()).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    deleteCycle(cycle) {
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getCycleUri(cycle.getId())).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    hydrateCycles(cycles, filter, pageNr = 1) {
      let that = this;
      let localCycles = new LoadableCollection();
      let filterParams = {
        page: pageNr,
      };

      if (typeof filter.status !== "undefined") {
        filterParams.status = filter.status;
      }

      that.queueJob();
      this.axios.get(this.$store.state.config.getCyclesUri(), {params: filterParams}).then(
          response => {
            let content = response.data;

            localCycles.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (cycle) {
              cycle = new Cycle(cycle);
              cycle.setLoaded(true);
              localCycles.push(cycle);
            });
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        cycles.setPagination(localCycles.getPagination());
        localCycles.getItems().forEach(cycle => {
          cycles.push(cycle);
        });
        cycles.loaded();
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>

</style>